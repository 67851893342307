import React, { useEffect, useState } from "react";
import "./FooterPage.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { base_URL } from "../../Middleware/config";

export default function FooterPage(props) {
  const [tableColumn, setTableColumn] = useState([]);
  const [visitorCount, setVisitorCount] = useState();
  const aboutLinks = [
    "About Us",
    "Academic Programmes",
    "Notices",
    "Gallery",
    "Contact US",
  ];

  const aboutUsLink = [
    "/about",
    "/programs",
    "/notice",
    "/gallery",
    "/contact",
  ];

  const link = [
    "https://www.tuexam.edu.np/",
    "https://www.tuiost.edu.np/",
    "https://www.tudoms.org/",
    "https://tufoe.edu.np/",
    "http://fohss.tu.edu.np/",
    "https://tuservicecommission.edu.np/",
    "http://www.tribhuvan-university.edu.np/",
  ];

  const importantLinks = [
    "TUEXAM",
    "TUIOST",
    "TUDOMS",
    "TUFOE",
    "FOHSS",
    "TU Service Commission",
    "Tribhuvan University",
  ];

  // const [visitorCount, setVisitorCount] = useState(0);
  const getContact = async () => {
    await axios.get(`${base_URL}/contact?id=1`).then((result) => {
      setTableColumn(result.data.body);
    });
  };

  async function getVisitorCount() {
    await axios.get(`${base_URL}/counter`).then((count) => {
      setVisitorCount(count.data.visit);
    });

    //ONLINE API SYSTEM COUNTER NOT WORKING
    // const response = await fetch("https://counterapi.com/api/trmc.edu.np/view?readOnly=true&startNumber=8100");
    // const visitorData = await response.json();
    // setVisitorCount(visitorData.value);
  }

  useEffect(() => {
    getVisitorCount();
  }, []);

  useEffect(() => {
    getContact();
    //eslint-disable-next-line
  }, []);

  return (
    <footer className="footer-wrapper">
      <div className="footer-content">
        <div className="item item-campus">
          <p className="title">Campus Info</p>
          <p>
            <strong>{tableColumn.title}</strong>
          </p>
          <p>
            <strong>Phone:</strong> {tableColumn.phone}
          </p>
          <p>
            <strong>Email:</strong> {tableColumn.email}
          </p>
          <span>
            <strong>Address:</strong> {tableColumn.city}, {tableColumn.state},{" "}
            {tableColumn.country}, {tableColumn.pinCode}
            <div className="links">
              <a
                style={{
                  display: "block",
                  width: "fit-content",
                  background: "transparent",
                }}
                className="fb"
                href={tableColumn.facebookLink}
              >
                <i className="fa fa-facebook" style={{ fontSize: "16px" }}></i>
              </a>
              <a
                style={{
                  display: "block",
                  width: "fit-content",
                  background: "transparent",
                }}
                className="fb"
                href={tableColumn.instagramLink}
              >
                <i
                  className="fa fa-instagram"
                  style={{ fontSize: "16px", color: "white" }}
                ></i>
              </a>
              <a
                style={{
                  display: "block",
                  width: "fit-content",
                  background: "transparent",
                }}
                className="fb"
                href={tableColumn.twitterLink}
              >
                <i
                  className="fa fa-twitter"
                  style={{ fontSize: "16px", color: "white" }}
                ></i>
              </a>
              <a
                style={{
                  display: "block",
                  width: "fit-content",
                  background: "transparent",
                }}
                className="fb"
                href={tableColumn.linkdinLink}
              >
                <i
                  className="fa fa-linkedin"
                  style={{ fontSize: "16px", color: "white" }}
                ></i>
              </a>
            </div>
          </span>
        </div>
        <FooterLinks links={aboutUsLink} title={"Links"} items={aboutLinks} />
        <FooterLinks
          links={link}
          title={"Important Links"}
          items={importantLinks}
        />
      </div>
      <div className="copyright-section">
        <p className="copyright-text">
          © Copyright @ {new Date().getFullYear()} Thakur Ram Multiple Campus.
          All Rights Reserved And Developed by{" "}
          <a
            style={{ color: "white", textDecoration: "none" }}
            href="http://lennobyte.com"
          >
            Lennobyte Solutions Pvt. Ltd.
          </a>
        </p>
        {/* <div class="counterapi"  readOnly="false" startNumber="8100" noIcon="true" noCss="true"  ></div> */}
        <p className="visitorsCount">
          <a
            style={{ color: "white", textDecoration: "none" }}
            // href="https://counterapi.com/stats/trmc.edu.np/view/%2F"
            href="#"
          >{`Page Visit: ${visitorCount}`}</a>
        </p>
      </div>
    </footer>
  );
}

function FooterLinks(props) {
  return (
    <div className="item item-about">
      <p className="ft-link-title">{props.title}</p>
      <ul>
        {props.items.map((itm, index) => {
          return (
            <li key={index} className="ft-list">
              {props.title === "Links" ? (
                <Link to={props.links[index]}>{itm}</Link>
              ) : (
                <a href={props.links[index]}>{itm}</a>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
