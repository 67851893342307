import React from "react";
import "./BaseTable.css";
// import { FaDownload } from "react-icons/fa";
import { zipFiles, getNepaliDate } from "../../utils";

export default function BaseTable({ tableName, pageRows, depId }) {
  const isAdmission = depId === 12;

  const handleSave = async function (files) {
    await zipFiles(files);
  };

  if (pageRows === null) return <h3>No data found</h3>;

  return (
    <div className="tbl-container">
      <table className="notice__table">
        <caption>{tableName}</caption>
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            {isAdmission && <th>Last Date</th>}
            <th>Downloads</th>
          </tr>
        </thead>
        <tbody id="row-filter">
          {pageRows.map((data) => {
            const filesToDownload = data.fileName.split(",");
            const downloadNames = data.orignalFileName.split(",");
            const files = filesToDownload.map((file, index) => {
              let x = {};
              x.name = file;
              x.downloadName = downloadNames[index];
              return x;
            });

            return (
              <tr key={data.id}>
                <td width="200px" style={{ fontFamily: "serif" }}>
                  {getNepaliDate(data.createdDate)}
                </td>
                <td>
                  <p key={data.id}>{data.description}</p>
                </td>
                {isAdmission && (
                  <td align="center">
                    <p>{getNepaliDate(data.lastDate)}</p>
                  </td>
                )}
                <td>
                  {files.length > 0 && (
                    <div
                      onClick={() => {
                        handleSave(files);
                      }}
                    >
                      {/* <p>
                        {files.length === 1
                          ? downloadNames[0]
                          : downloadNames[0] + "..."}
                      </p> */}
                      <button className="pub-dl-btn">Download</button>
                      {/* <FaDownload /> */}
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
