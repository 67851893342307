import JSZip from "jszip";
import FileSaver from "file-saver";
import { base_URL } from "./Middleware/config";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
const NepaliDate = require('nepali-date');
const marqueeText =
  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima quod molestiae, in sed veniam optio reprehenderit veritatis dolore voluptatibus harum voluptatem inventore quo illo quia consequuntur perspiciatis doloremque consequatur numquam vero quam incidunt? Ratione sint eius voluptatibus voluptate, cumque excepturi sed tempora eum ipsa obcaecati quas a ea atque.";

const image1 =
  "https://avatars.mds.yandex.net/i?id=b6afa225beeee6df01249a7162b927c54e38c5c9-5232405-images-thumbs&n=13";

export { marqueeText, image1 };

export const columns = ["Date", "Description", "Last Date", "Downloads"];
export const examColumns = [...columns, "Last Date"];

export const DEPARTMENTS = [
  { name: "All", depId: null, route: "/notice" },
  { name: "Admission", depId: 12, route: "/admission" },

  {
    name: "Administration",
    depId: 11,
    route: "/administration",
  },
  { name: "Exam", depId: 10, route: "/exam" },
  { name: "Downloads", depId: 13, route: "/downloads" },
];

export function lineClamp(text, start, end) {
  if (text.length <= end) return text;
  const orig = new String(text);
  const modText = orig.substring(start, end);
  return `${modText.substring(0, modText.lastIndexOf(" "))}...`;
}

export function formatNoticeDate(date) {
  const nepDate = getNepaliDate(date)
  const newDate = new NepaliDate(nepDate);
  const dateObj = {};
  const dt = newDate.format('DD') // use dd for devnagri scripts
  const month = newDate.format('MMMM') // use mmmm for devnagri characters
  dateObj.month = month;
  dateObj.date = dt;
  // const dt = new Date(date);

  // dateObj.month = dt.toLocaleDateString("default", { month: "long" });
  // dateObj.date = dt.getDate();
  return dateObj;
}

export const NOTICE_PER_PAGE = 10;

export function createBlob(data) {
  return new Blob([data], "image/jpeg");
}

export async function zipFiles(files) {
  if (files.length === 1) {
    FileSaver.saveAs(
      `${base_URL}/images/${files[0].name}`,
      files[0].downloadName
    );
  } else {
    const zip = new JSZip();
    const promises = files.map((file) => getImage(file.name));
    Promise.all(promises).then((blobs) => {
      files.forEach((f, index) => {
        // console.log(`adding ${f.downloadName} with ${blobs[index]} to zip`);
        zip.file(f.downloadName, blobs[index]);
        // console.log(`added ${f.downloadName} with ${blobs[index]} to zip`);
      });

      zip
        .generateAsync({ type: "blob" })
        .then((content) => FileSaver.saveAs(content, "attachments"));
    });
  }
}

/**
 *
 * @param {file} file name of file that we want to download.
 * @returns A Promise object that resolves to a Blob for downloaded image,
 * or rejects with an error message.
 */
function getImage(file) {
  return new Promise((resolve, reject) => {
    fetch(`${base_URL}/images/${file}`)
      .then((resp) => resp.blob())
      .then((blob) => resolve(blob))
      .catch((err) => reject(err));
  });
}

export function searchNotice(notice, query) {
  if (query.length === 0) return;
  return notice.filter(
    (n) => n.description.toLowerCase().includes(query.toLowerCase()) || n.createdDate.includes(query)
  );
}

/**
 * Custom Hook to fetch resource from REST API.
 * @param {*} url url address to fetch resource from. 
 * @returns either data , loadstate , or error
 */
export function useFetch(url) {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null)

  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    axios.get(url, { signal: controller.signal }).then(response => {
      if (response.status === 200)
        setData(response.data.body);
      else
        throw Error("Something went wrong while fetching the resource!")
    }).catch(err => {
      if (err.message === "canceled")
        return
      else {
        console.log("error ", err.message);
        setError(err);
      }
    }).finally(() => {
      setLoading(false);
    });
    return () => {
      setLoading(false);
      controller.abort();
    }
  }, [url]);

  return { data, loading, error }
}

export function getNepaliDate(date) {
  try {
    const dateString = date;
    const jsDate = new Date(dateString);
    return new NepaliDate(jsDate).toString();
  } catch (error) {
    console.log('Nepali date conversion error:', error)
    return '';
  }

}

// middleware to handle response and redirect to error page
// const handleResponse = (resp) => {
//   if (resp.status === 503) {
//     throw new Error('503 Service Unavailable');
//   }
//   else {
//     return resp;
//   }
// }

